<template>
  <div class="page-wrap"> 
    <img
      class="page-img"
      :src="`https://cdn.qiumo.net.cn/allsmarketmaterial/test/bg.png`"
    />
    <input
      class="input"
      v-model="phone_number"
      type="tel"
      maxlength="11"
      placeholder="请输入登录的手机号码"
    />
    <img class="order-button" src="https://cdn.qiumo.net.cn/allsmarketmaterial/test/btn.png" @click="onSubmitOrder" />
    <div class="protocol-wrap" @click="checked = !checked">
      <img class="protocol-check" :src="`https://cdn.qiumo.net.cn/allsmarketmaterial/test/${checked ? 'selected_icon' : 'unselected_icon'
        }.png`" />
      <div class="protocol-text">业务资费：每月仅{{type =="oneFen" ? "0.01" : ( type == "threeYuan" ? "3": "")}}元，输入手机号后使用支付宝,活动测试链接，无实际权益</div>
    </div>
    <div class="tag">铮行客服热线:4001180238</div>
    <van-popup v-model="visibility" class="dialog-wrap">
      <div class="dialog-content">
        <img
          class="dialog-bg"
          src="https://cdn.qiumo.net.cn/allsmarketmaterial/orderManipulation_zhengxing/dialog_bg.png"
        />
        <div class="dialog-text">{{ protocolContent }}</div>
        <img
          class="dialog-close"
          src="https://cdn.qiumo.net.cn/allsmarketmaterial/orderManipulation_zhengxing/close_icon.png"
          @click="visibility = false"
        />
      </div>
    </van-popup>
  </div>
</template>

<script>
import { alipaySinglePayAPI } from "@/api/index";
import { Toast } from "vant";
const protocolContent = `本协议是用户（以下亦称“您”）与铮行公司平台所有者浙江铮行科技有限责任公司（以下简称为“铮行公司”）之间就铮行公司平台服务等相关事宜所订立的契约，请您仔细阅读本注册协议，您点击“同意以下协议，提交”按钮后，本协议即构成对双方有约束力的法律文件。

第1条本平台服务条款的确认和接纳

1.1本平台的各项电子服务的所有权和运作权归铮行公司所有。用户同意所有注册协议条款并完成注册程序，才能成为本平台的正式用户。用户确认：本协议条款是处理双方权利义务的契约，始终有效，法律另有强制性规定或双方另有特别约定的，依其规定。

1.2用户点击同意本协议的，即视为用户确认自己具有享受本平台服务、下单购物等相应的权利能力和行为能力，能够独立承担法律责任。

1.3如果您在18周岁以下，您只能在父母或监护人的监护参与下才能使用本平台。

1.4铮行公司保留在中华人民共和国大陆地区法施行之法律允许的范围内独自决定拒绝服务、关闭用户账户、清除或编辑内容或取消订单的权利。

第2条本平台服务

2.1铮行公司通过互联网依法为用户提供互联网信息等服务，用户在完全同意本协议及本平台规定的情况下，方有权使用本平台的相关服务。

第3条用户信息

3.1用户应自行诚信向本平台提供注册资料，用户同意其提供的注册资料真实、准确、完整、合法有效，用户注册资料如有变动的，应及时更新其注册资料。如果用户提供的注册资料不合法、不真实、不准确、不详尽的，用户需承担因此引起的相应责任及后果，并且铮行公司保留终止用户使用铮行公司各项服务的权利。

3.2用户在本平台进行浏览、下单购物等活动时，可能涉及用户真实姓名/名称、通信地址、联系电话、电子邮箱、银行账户、房产信息等隐私信息的，本平台将予以严格保密，除非得到用户的授权或法律另有规定，本平台不会向外界披露用户隐私信息。

3.3用户同意，铮行公司拥有通过邮件、短信、电话等形式，向在本平台注册、购物用户、收货人发送订单信息、促销活动等告知信息的权利。

第4条用户依法言行义务 本协议依据国家相关法律法规规章制定，用户同意严格遵守以下义务：

（1）不得传输或发表：煽动抗拒、破坏宪法和法律、行政法规实施的言论，煽动颠覆国家政权，推翻社会主义制度的言论，煽动分裂国家、破坏国家统一的的言论，煽动民族仇恨、民族歧视、破坏民族团结的言论；

（2）从中国大陆向境外传输资料信息时必须符合中国有关法规；

（3）不得利用本平台从事洗钱、窃取商业秘密、窃取个人信息等违法犯罪活动；

（4）不得干扰本平台的正常运转，不得侵入本平台及国家计算机信息系统；

（5）不得传输或发表任何违法犯罪的、骚扰性的、中伤他人的、辱骂性的、恐吓性的、伤害性的、庸俗的，淫秽的、不文明的等信息资料；

（6）不得传输或发表损害国家社会公共利益和涉及国家安全的信息资料或言论；

（7）不得教唆他人从事本条所禁止的行为； （8）不得利用在本平台注册的账户进行牟利性经营活动；

（9）不得发布任何侵犯他人著作权、商标权等知识产权或合法权利的内容； 用户应不时关注并遵守本平台不时公布或修改的各类合法规则规定。 本平台保有删除站内各类不符合法律政策或不真实的信息内容而无须通知用户的权利。 若用户未遵守以上规定的，本平台有权作出独立判断并采取暂停或关闭用户帐号等措施。用户须对自己在网上的言论和行为承担法律责任。

第5条商品信息 本平台上的商品价格、数量、是否有货等商品信息随时都有可能发生变动，本平台不作特别通知。由于平台上商品信息的数量极其庞大，虽然本平台会尽最大努力保证您所浏览商品信息的准确性，但由于众所周知的互联网技术因素等客观原因存在，本平台网页显示的信息可能会有一定的滞后性或差错，对此情形您知悉并理解；铮行公司欢迎纠错，并会视情况给予纠错者一定的奖励。 为表述便利，商品和服务简称为“商品”或“货物”。

第6条订单

6.1在您下订单时，请您仔细确认所购商品的名称、价格、数量、规格、联系电话、收货人等信息。收货人与用户本人不一致的，收货人的行为和意思表示视为用户的行为和意思表示，用户应对收货人的行为及意思表示的法律后果承担连带责任。

6.2除法律另有强制性规定外，双方约定如下：本平台上销售方展示的商品和价格等信息仅仅是要约邀请，您下单时须填写您的联系方式、姓名等内容；系统生成的订单信息是计算机信息系统根据您填写的内容自动生成的数据，仅是您向销售方发出的合同要约；销售方收到该订单信息后，已将货物/服务实际直接向您发出时，方视为您与销售方之间就发出的货物/服务建立了合同关系。

第7条支付及自动续费规则

7.1用户在铮行公司平台中选购商品并提交订单后，用户需按照铮行公司平台提供的网上支付通道（下称“支付渠道”）在提交订单后合理的时间内支付显示的应付款项，如在显示的时间内用户未及时付款，则此笔订单将根据阶段的不同会被取消或无法进行下一阶段的服务。

7.2 您点击订阅、开通及使用腾讯视频系列会员自动续费服务(以下称“本自动续费服务”),则视为您同意本规则。 您理解并同意：

（1）如您通过支付渠道开通本自动续费服务，且在订阅期内未主动明确取消本自动续费服务，则支付渠道将在每个订阅期即将到期的前24小时从您开通本自动续费服务时绑定的支付渠道账户(以下统称“账户”)余额中自动代扣下一个订阅期的服务费，从而延长该订阅期对应的会员领取服务有效期(具体以支付渠道实际扣费规则为准)。您应关注上述账户及可扣款的余额状况，如因账户问题或余额不足导致续费失败等风险及/或损失将由您自行承担。 为便于您了解自动续费情况，支付渠道及/铮行公司将以【短信、站内信、微信推送或邮件】等方式进行扣款前通知，请您注意接收。需要提醒您特别注意，基于自动续费服务的特点，如果您在订阅期内未明确取消自动续费服务，支付渠道将根据您的委托向相关主体发出下一个订阅期的代扣指令，原则上对基于该指令扣除的费用将不予退还。

（2）订阅期：以订阅页面设置为准，会员可自行选择。

（3）您确认并同意，铮行公司拥有调整服务价格或停止提供自动续费服务的权利。如铮行公司拟对您开通自动续费服务时的服务价格做出调整，铮行公司将以适当方式(包括但不限于站内公告、短信等)事先通知您。如您不同意服务价格调整，您可以根据本规则取消本自动续费服务；如您未取消本自动续费服务，则支付渠道后续代扣的费用将以调整后的服务费用为准；如您在订阅期到期前24小时内取消，则支付渠道将可能会按照调整后的服务费用扣除下一个订阅期的服务费用后停止代扣服务。在本自动续费服务取消后，如您再次开通自动续费服务，支付渠道自动代扣的服务费用以届时页面显示的服务费用为准。

（4）取消方式 支付宝：【我的】-【设置】-【支付设置】-【免密支付/自动扣款】，选择随心淘取消订阅 微信：【我的】-【服务】-【钱包】-【支付设置】-【自动续费】，选择随心淘取消订阅

第8条退订

8.1基于本平台提供服务的特殊性，不支持退换，一经订阅，即为有效，请您在购买前务必谨慎考虑。

第 9条所有权及知识产权条款

9.1用户一旦接受本协议，即表明该用户主动将其在任何时间段在本平台发表的任何形式的信息内容（包括但不限于客户评价、客户咨询、各类话题文章等信息内容）的财产性权利，如著作权财产权（包括并不限于：复制权、发行权、出租权、展览权、表演权、放映权、广播权、信息网络传播权、摄制权、改编权、翻译权、汇编权以及应当由著作权人享有的其他可转让权利），全部归铮行公司所有，用户同意铮行公司有权就任何主体侵权而单独提起诉讼。

9.2用户同意并已充分了解本协议的条款，承诺不将已发表于本平台的信息，以任何形式发布或授权其它主体以任何方式使用（包括但限于在各类平台、媒体上使用）。

9.3铮行公司是本平台的制作者,拥有此平台内容及资源的著作权等合法权利,受国家法律保护,有权不时地对本协议及本平台的内容进行修改，并在本平台张贴，无须另行通知用户。在法律允许的最大限度范围内，铮行公司对本协议及本平台内容拥有解释权。

9.4除法律另有强制性规定外，未经铮行公司明确的特别书面许可,任何单位或个人不得以任何方式非法地全部或部分复制、转载、引用、链接、抓取或以其他方式使用本平台的信息内容，否则，铮行公司有权追究其法律责任。

9.5本平台所刊登的资料信息（诸如文字、图表、标识、按钮图标、图像、声音文件片段、数字下载、数据编辑和软件），均是铮行公司的财产，受中国和国际版权法的保护。本平台上所有内容的汇编是铮行公司的排他财产，受中国和国际版权法的保护。本平台上所有软件都是铮行公司或其关联公司或其软件供应商的财产，受中国和国际版权法的保护。

第10条责任限制及不承诺担保 除非另有明确的书面说明,本平台及其所包含的或以其它方式通过本平台提供给您的全部信息、内容、材料、产品（包括软件）和服务，均是在“按现状”和“按现有”的基础上提供的。 除非另有明确的书面说明,铮行公司不对本平台的运营及其包含在本平台上的信息、内容、材料、产品（包括软件）或服务作任何形式的、明示或默示的声明或担保（根据中华人民共和国法律另有规定的以外）。 铮行公司不担保本平台所包含的或以其它方式通过本平台提供给您的全部信息、内容、材料、产品（包括软件）和服务、其服务器或从本平台发出的电子信件、信息没有病毒或其他有害成分。 如因不可抗力或其它本平台无法控制的原因使本平台销售系统崩溃或无法正常使用导致网上交易无法完成或丢失有关的信息、记录等，铮行公司会合理地尽力协助处理善后事宜。

第11条协议更新及用户关注义务 根据国家法律法规变化及平台运营需要，铮行公司有权对本协议条款不时地进行修改，修改后的协议一旦被张贴在本平台上即生效，并代替原来的协议。用户可随时登陆查阅最新协议；用户有义务不时关注并阅读最新版的协议及平台公告。如用户不同意更新后的协议，可以且应立即停止接受铮行公司平台依据本协议提供的服务；如用户继续使用本平台提供的服务的，即视为同意更新后的协议。本平台建议您在使用本平台之前阅读本协议及本平台的公告。 如果本协议中任何一条被视为废止、无效或因任何理由不可执行，该条应视为可分的且并不影响任何其余条款的有效性和可执行性。

第12条法律管辖和适用 本协议的订立、执行和解释及争议的解决均应适用在中华人民共和国大陆地区适用之有效法律（但不包括其冲突法规则）。 如发生本平台服务条款与适用之法律相抵触时，则这些条款将完全按法律规定重新解释，而其它有效条款继续有效。 如缔约方就本协议内容或其执行发生任何争议，双方应尽力友好协商解决；协商不成时，双方均同意提请杭州仲裁委员会按照其仲裁规则进行仲裁；该仲裁裁决为终局裁决，对双方均有约束力。

第13条其他

13.1铮行公司尊重用户和消费者的合法权利，本协议及本平台上发布的各类规则、声明等其他内容，均是为了更好的、更加便利的为用户和消费者提供服务。本平台欢迎用户和社会各界提出意见和建议，铮行公司将虚心接受并适时修改本协议及本平台上的各类规则。

13.2本协议内容中以黑体、加粗、下划线、或斜体等方式显著标识的条款，请用户着重阅读。

13.3您点击本协议上方的“同意以下协议，提交”按钮即视为您完全接受本协议，在点击之前请您再次确认已知悉并完全理解本协议的全部内容。

`;
export default {
  data() {
    return {
      phone_number: "",
      checked: false,
      visibility: false,
      protocolContent,
    };
  },
  computed: {
    type() {
      return this.$route.query.type || "oneFen";
    },
  },
  methods: {
    onSubmitOrder() {
      if (!this.phone_number) return Toast("请输入手机号码");
      let filterPhone = /^1[3456789]\d{9}$/;
      const pass = filterPhone.test(this.phone_number);
      if (!pass) return Toast("手机号码格式不正确");
      if (!this.checked) return Toast("请阅读并同意服务协议");
      this.$toast({
        type: "loading",
        message: "加载中...",
        duration: 0,
        forbidClick: true,
      });
      const { from: order_channel_from = "" } = this.$route.query;
      alipaySinglePayAPI(
        { order_channel_from },
        {
          phone_number: this.phone_number,
          item_name:
            this.type == "oneFen"
              ? "一分会员"
              : this.type == "threeYuan"
              ? "三元会员"
              : "",
        }
      )
        .then((res) => {
          this.$toast.clear();
          if (res.code === 0) {
            location.href = res.sign_str;
          } else {
            Toast.fail(res.message || "订购失败");
          }
        })
        .catch((err) => {
          Toast.fail("服务器错误");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.page-wrap {
  position: relative;
  background-color: #ffbc02;
  height: 100%;
  .page-img {
    width: 100vw;
  }
  .input {
    width: 660px;
    height: 98px;
    border: none;
    border-radius: 49px;
    background: #fff;
    position: absolute;
    left: 50%;
    top: 882px;
    transform: translateX(-50%);
    padding: 0 32px;
    font-size: 32px;
    color: #a21008;
    text-align: center;

    &::placeholder {
      color: #a21008;
      font-size: 32px;
      opacity: 0.5;
    }
  }
  .order-button {
    width: 620px;
    height: 112px;
    border: none;
    position: absolute;
    left: 50%;
    top: 1025px;
    transform: translateX(-50%);
  }
 
  .protocol-wrap {
    width: 100vw;
    position: absolute;
    left: 50%;
    top: 1208px;
    transform: translateX(-50%);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    font-size: 22px;
    line-height: 22px;
    font-weight: bold;
    color: #000;

    .protocol-check {
      width: 24px;
      height: 24px;
      margin-right: 10px;
      margin-top: 8px;
    }

    .protocol-text {
      font-size: 24px;
      width: 560px;
      letter-spacing: 0px;
      text-align: center;
      color: #a21008;
      line-height: 36px;
    }
  }
  .tag {
    text-align: center;
    font-size: 28px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 36px;
    letter-spacing: 0px;
    color: #a21008;
    position: absolute;
    left: 0;
    right: 0;
    top: 1322px;
  }
  .dialog-wrap {
    background: none;
    overflow: initial;
    .dialog-content {
      position: relative;
      .dialog-bg {
        width: 90vw;
      }
      .dialog-text {
        height: 800px;
        overflow: auto;
        white-space: pre-wrap;
        position: absolute;
        top: 120px;
        left: 50%;
        transform: translateX(-50%);
        width: 80vw;
        font-size: 28px;
      }
      .dialog-close {
        position: absolute;
        bottom: -100px;
        left: 50%;
        transform: translateX(-50%);
        width: 60px;
        height: 60px;
        z-index: 100;
      }
    }
  }
}
</style>
